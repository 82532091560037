import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';

import { Section, Text, Button } from '@utils';
import Feature from '@component/Work/Feature';
import SEO from '@component/SEO';

const Work: React.FC = () => {
  // Queries
  // const data = useStaticQuery(graphql`
  //   {
  //     allSanityWork(sort: { order: DESC, fields: _createdAt }) {
  //       edges {
  //         node {
  //           _key
  //           title
  //           slug {
  //             current
  //           }
  //           description
  //           thumbnail {
  //             asset {
  //               gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // `);

  return (
    <>
      <SEO
        title="Work"
        path="/work"
        description="Interesting in learning more about Coral Dev? Checkout our recent works for a variety of Minecraft servers and personnel."
      />
      <Section id="hero">
        <div className="text-center">
          <Text style="h2" tag="h1">
            <span className="bg-clip-text bg-gradient-to-r from-coral to-reef text-transparent">
              Our Work
            </span>
          </Text>
        </div>
      </Section>
      <Section id="coming-soon">
        <div className="space-y-10 text-center">
          <Text className="text-center">
            We're currently re-working our portfolio. Come join our Discord to
            checkout our previous works in the meantime!
          </Text>
          <Button
            type="external"
            variant="primary"
            href="https://discord.coraldev.net"
          >
            Join Our Discord Server
          </Button>
        </div>
      </Section>

      {/* <Section id="works">
        <div className="md:gap-16 lg:gap-32 grid gap-12">
          {data.allSanityWork.edges.map(({ node }: any) => (
            <Feature
              showBtn
              href={node.slug.current}
              title={node.title}
              description={node.description}
              image={node.thumbnail.asset.gatsbyImageData}
            />
          ))}
        </div>
      </Section> */}
    </>
  );
};

export default Work;
